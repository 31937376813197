<template>
  <div class="container start-test">
    <div class="title">
      <div class="row">
        <div class="col-sm-12">
          <h2 style="margin: 20px 0 0 0">Start Your {{ test.title }}</h2>
        </div>
      </div>
      <hr />

      <div>
        <el-form label-width="120px" :model="form">
          <el-form-item label="Test:">
            <el-button type="text" @click="downloadFile(test.url)">
              <i class="el-icon-document"></i>
              {{ test.title }}
              <i class="el-icon-download el-icon--right"></i>
            </el-button>
          </el-form-item>
          <el-form-item label="Essay:">
            <el-radio-group v-model="hasEssay" size="small">
              <el-radio-button label="1">Yes</el-radio-button>
              <el-radio-button label="0">No</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div class="text-center">
          <el-button type="success" @click="startTimer">
            Go To Test Timer Page
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Tests from "@/apis/tests.js";
// import TOEFL from "@/apis/tests.js";

export default {
  metaInfo() {
    return {
      title: "Start Your Test - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      test: {
        type: "",
        url: "",
        title: ""
      },
      hasEssay: "0"
    };
  },
  computed: {
    examId() {
      let examId = 0;
      if (this.$route.query.exam_id) {
        examId = parseInt(this.$route.query.exam_id);
      }
      return examId;
    },
    userExamId() {
      let userExamId = 0;
      if (this.$route.query.user_exam_id) {
        userExamId = parseInt(this.$route.query.user_exam_id);
      }
      return userExamId;
    }
  },
  watch: {},

  mounted() {
    this.getFile();
  },

  methods: {
    async getFile() {
      let res = await Tests.getFile(this.examId);
      this.test.url = res.test_url;
      this.test.title = res.title;
      this.test.type = res.type;
    },
    startTimer() {
      this.routerPush({
        name: "Timer",
        query: {
          type: this.test.type.toLowerCase(),
          exam_id: this.examId,
          hasEssay: this.hasEssay,
          user_exam_id: this.userExamId
        }
      });
    },
    downloadFile(url) {
      let a = document.createElement("a");
      a.href = url;
      a.download = true;
      a.target = "_blank";
      a.click();
    }
  }
};
</script>

<style scoped>
.select-test {
  width: 100%;
}
.start-test >>> .el-form-item__label {
  font-size: 14px;
  font-weight: 700;
}
.start-test >>> .el-form-item__content {
  font-size: 14px;
}
</style>
